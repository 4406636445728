
import { computed, defineComponent, ref } from 'vue'
import TmModal from '@/components/shared/modal/TmModal.vue'
import TmButton from '@/components/shared/TmButton.vue'
import ComposeScheduleSection from '@/components/pages/compose/ComposeScheduleSection.vue'
import { useModals } from '@/compositions/modals'

export default defineComponent({
  name: 'ComposePreviewModal',
  components: { ComposeScheduleSection, TmButton, TmModal },
  props: {
    total: {
      type: Number,
    },
    isSchedule: {
      type: Boolean,
    },
  },
  setup() {
    const items = [
      [
        { label: 'From', value: '+447441447724' },
        { label: 'To', value: '+37282720137' },
        { label: 'Message content', value: 'A quick reminder of your appointment at 11:15am on June 8th at ABC Dentists. If you need to change this please call us on 01615555555 or reply to this text.' },
        { label: 'Total characters', value: '3 characters' },
        { label: 'Message parts', value: '1 part' },
        { label: 'Cost of this message', value: '$0.050' },
        { label: 'Total session cost', value: '$1.034' },
      ],
      [
        { label: 'From', value: '+123441445624' },
        { label: 'To', value: '+17283210111' },
        { label: 'Message content', value: 'Hi First name, it’s Charles from Textmagic Edward Tark has asked me to give you a quick call. Please can you let me know when you are free to talk and I’ll phone you? Many thanks.' },
        { label: 'Total characters', value: '2 characters' },
        { label: 'Message parts', value: '2 part' },
        { label: 'Cost of this message', value: '$0.030' },
        { label: 'Total session cost', value: '$2.034' },
      ],
    ]
    const currentIndex = ref(0)
    const currentTemplate = computed(() => items[currentIndex.value])
    const setIndex = (value = 1) => {
      currentIndex.value = currentIndex.value + value
    }

    const { openModal } = useModals()

    return {
      items,
      currentIndex,
      currentTemplate,
      setIndex,
      openModal,
    }
  },
})
